var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-password-and-privacy-container" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          !_vm.isProfileForm
            ? _c(
                "v-row",
                {
                  staticClass: "mb-4 px-0 px-sm-3",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("profile.contacts.email"))
                    }
                  }),
                  _c("label", {
                    staticClass: "font-weight-bold ml-4",
                    domProps: { innerHTML: _vm._s(_vm.userData.email) }
                  })
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "mb-4 ma-sm-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    staticClass: "mr-3",
                    domProps: {
                      innerHTML: _vm._s(`${_vm.$t("profile.oldPassword")} *`)
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      disabled: _vm.response.status === 2,
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.oldPasswordRules,
                      required: "",
                      outlined: "",
                      dense: ""
                    },
                    on: {
                      "click:append": _vm.toggleShowPassword,
                      change: _vm.validate
                    },
                    model: {
                      value: _vm.userData.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "oldPassword", $$v)
                      },
                      expression: "userData.oldPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mb-4 ma-sm-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    staticClass: "mr-3",
                    domProps: {
                      innerHTML: _vm._s(
                        `${
                          _vm.showOldPassword
                            ? _vm.$t("profile.newPassword")
                            : _vm.$t("profile.password")
                        } *`
                      )
                    }
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g({ attrs: { color: "primary" } }, on),
                                [_vm._v(" $info ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("profile.passwordInfoTooltip")) +
                          " "
                      )
                    ]
                  ),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      disabled: _vm.response.status === 2,
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      required: "",
                      outlined: "",
                      dense: ""
                    },
                    on: {
                      "click:append": _vm.toggleShowPassword,
                      change: _vm.validate
                    },
                    model: {
                      value: _vm.userData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "password", $$v)
                      },
                      expression: "userData.password"
                    }
                  })
                ],
                1
              ),
              _vm.showPasswordFields
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("label", {
                        domProps: {
                          innerHTML: _vm._s(
                            `${_vm.$t("profile.passwordConfirm")} *`
                          )
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-sm-2",
                        attrs: {
                          disabled: _vm.response.status === 2,
                          "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                          type: _vm.showPassword ? "text" : "password",
                          rules: _vm.passwordConfirmRules,
                          required: "",
                          outlined: "",
                          dense: ""
                        },
                        on: {
                          "click:append": _vm.toggleShowPassword,
                          change: _vm.validate
                        },
                        model: {
                          value: _vm.passwordConfirm,
                          callback: function($$v) {
                            _vm.passwordConfirm = $$v
                          },
                          expression: "passwordConfirm"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "pa-0 d-flex justify-center justify-md-end",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-btn", {
                    staticClass: "text-uppercase",
                    attrs: {
                      color: "primary",
                      "x-large": "",
                      depressed: "",
                      block: ""
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("profile.updatePersonalData"))
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mt-10",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _vm.showPrivacyFields
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-sm-0 font-weight-bold",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("profile.privacyMarketing.title")
                          )
                        }
                      }),
                      _c("i18n", {
                        attrs: {
                          path: "profile.privacyMarketing.text",
                          tag: "div"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "link",
                              fn: function() {
                                return [
                                  _c("router-link", { attrs: { to: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-body-2 font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "profile.privacyMarketing.link"
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3525091857
                        )
                      }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                dense: "",
                                mandatory: "",
                                disabled: _vm.response.status === 2
                              },
                              model: {
                                value: _vm.supportData.privacy2,
                                callback: function($$v) {
                                  _vm.$set(_vm.supportData, "privacy2", $$v)
                                },
                                expression: "supportData.privacy2"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: true },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.accept"))
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  682736616
                                )
                              }),
                              _c("v-radio", {
                                attrs: { value: false },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.refuse"))
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1330384794
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showPrivacyFields
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-sm-0 font-weight-bold",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("profile.privacyProfiling.title")
                          )
                        }
                      }),
                      _c("i18n", {
                        attrs: {
                          path: "profile.privacyProfiling.text",
                          tag: "div"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "link",
                              fn: function() {
                                return [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/page/privacy-policy" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-body-2 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.privacyProfiling.link"
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2673390947
                        )
                      }),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                dense: "",
                                mandatory: "",
                                disabled: _vm.response.status === 2
                              },
                              model: {
                                value: _vm.supportData.privacy1,
                                callback: function($$v) {
                                  _vm.$set(_vm.supportData, "privacy1", $$v)
                                },
                                expression: "supportData.privacy1"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: true },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.accept"))
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  682736616
                                )
                              }),
                              _c("v-radio", {
                                attrs: { value: false },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.refuse"))
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1330384794
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showButtons
            ? _c(
                "v-layout",
                {
                  staticClass: "buttons align-sm-center",
                  attrs: {
                    column: _vm.$vuetify.breakpoint.xs,
                    row: _vm.$vuetify.breakpoint.smAndUp
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-5 mt-sm-0",
                      attrs: {
                        "no-gutters": "",
                        justify: "space-between",
                        "justify-sm": "start"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-btn", {
                            staticClass: "primary--text",
                            attrs: { "x-large": "", depressed: "", block: "" },
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("common.back"))
                            },
                            on: { click: _vm.back }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-btn", {
                            attrs: {
                              color: "primary",
                              "x-large": "",
                              depressed: "",
                              block: "",
                              disabled: !_vm.valid
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.isProfileForm
                                  ? _vm.$t("common.confirm")
                                  : _vm.$t("common.next")
                              )
                            },
                            on: { click: _vm.confirm }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("profile.mandatoryField"))
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }