var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "confirm-privacy-options-modal pa-4" },
    [
      _c("h2", { staticClass: "primary--text" }, [
        _vm._v(_vm._s(_vm.$t("profile.noPrivacy.warning")))
      ]),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "", justify: "end" } },
        [
          _c("v-btn", {
            staticClass: "primary--text",
            attrs: { depressed: "", outlined: "", large: "", text: "" },
            domProps: {
              innerHTML: _vm._s(_vm.$t("profile.noPrivacy.continue"))
            },
            on: { click: _vm.submit }
          }),
          _c("v-btn", {
            staticClass: "primary white--text ml-2",
            attrs: { depressed: "", outlined: "", large: "", text: "" },
            domProps: { innerHTML: _vm._s(_vm.$t("profile.noPrivacy.edit")) },
            on: { click: _vm.close }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }